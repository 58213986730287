<template>
  <div class="nk-block-head px-4 pb-0 mb-3 d-flex justify-content-between">
    <h5 class="text-capitalize">{{$t('Advisors') }}</h5>
    <nk-button type="primary" size="sm" outline v-on:click="[advisorAddEditForm.modalOpen=true]">
      <nio-icon icon="ni-plus"></nio-icon> {{$t('Add Advisor')}}
    </nk-button>
  </div>
  <div v-if="loadingData" class="text-center">
    <ion-spinner></ion-spinner>
  </div>
  <div v-else>
    <div class="card-inner p-0">
      <special-table>
        <table-row is-heading>
          <table-col>
            <span class="sub-text">{{ $t('User') }}</span>
          </table-col>
          <table-col break-point="md">{{ $t('Active Status') }}</table-col>
          <table-col break-point="md">
            <nio-icon icon="ni-notes-alt" class="mr-1" style="font-size: 1.1rem;" v-tooltip="$t('Notes')"></nio-icon>
          </table-col>
          <table-col break-point="md">Leads</table-col>
          <table-col break-point="lg">{{ $t('Joined') }}</table-col>
          <table-col break-point="lg">{{ $t('Last Login') }}</table-col>
          <table-col class="nk-tb-col-tools"></table-col>
        </table-row>

        <table-row v-for="advisor in advisors" :key="advisor.id">
          <table-col>
            <user-small-card :user-name="advisor.name" :user-email="advisor.email" :image="advisor.dp" :initials="advisor.initials" :is-online="advisor.is_online">
              <template v-slot:more_info>
                <div class="mt-n1 lh-n medium">
                  <div>{{ $t('ID')+': '+advisor.id }}</div>
                  <div v-if="advisor.city">{{ $t('City')+': '+advisor.city }}</div>
                </div>
              </template>
            </user-small-card>
          </table-col>
          <table-col break-point="md">
            <div class="d-flex align-items-center">
                            <span class="mr-1" :class="[advisor.active_status == 0 ? 'text-danger' : ( advisor.active_status == 1 ? 'text-success' : 'text-warning' )]">
                            {{ $t(advisor.active_status == 0 ? 'Inactive' : ( advisor.active_status == 1 ? 'Active' : 'On hold' ) ) }}
                            </span>
              <a href="javascript:;" class="lh-0" v-on:click="changeAdvisorActiveStatus(advisor)">
                <nio-icon icon="ni-edit"></nio-icon>
              </a>
            </div>
          </table-col>
          <table-col break-point="md">
            <a href="javascript:;" v-on:click="F_getUserNotes(advisor.user_id)" class="d-flex align-items-center">
              <nio-icon icon="ni-notes-alt" class="mr-1" style="font-size: 1.1rem;"></nio-icon>
              <span class="text-primary">{{advisor.notes_count}}</span>
            </a>
          </table-col>
          <table-col break-point="md">
            <ul class="list-status">
              <li :title="$t('Assigned')+' '+$t('this week')"><nio-icon class="text-info" icon="ni-clipboad-check"></nio-icon> <span>{{ advisor.assigned_leads.current_week }}</span></li>
              <li :title="$t('Available')+' '+$t('this week')"><nio-icon icon="ni-clipboard"></nio-icon> <span>{{ advisor.available_leads }}</span></li>
              <li :title="$t('Assigned')+' '+$t('last week')"><nio-icon icon="ni-clipboad-check-fill" class="text-primary"></nio-icon> <span>{{ advisor.assigned_leads.last_week }}</span></li>
            </ul>
          </table-col>
          <table-col break-point="lg" class="lh-n">
            <template v-if="advisor.joined === 'pending'">
              <div class="text-danger">
                {{ $t('Not joined') }}
                <div class="text-muted small" v-if="advisor.last_invitation_time">{{ $t('Last invited')+': '+advisor.last_invitation_time }}</div>
              </div>
              <a role="button" class="text-purple d-inline-block medium" v-on:click="F_resendUserInvitationEmail(advisor)">{{ $t('Resend invitation') }}</a>
            </template>
            <span v-else>{{ advisor.joined }}</span>
          </table-col>
          <table-col break-point="lg">{{ advisor.last_seen }}</table-col>
          <table-col class="nk-tb-col-tools">
            <ul class="nk-tb-actions gx-1">
              <template v-if="isDesktop">
                <table-action icon="ni-list-check"  v-tooltip="$t('Assigned Leads')"
                              :to="{name: (userRole === 'is-company' ? 'comAdvisorProfile' : 'advisorProfile'), params: {id: advisor.id, tab: 'leads'}}"></table-action>
                <table-action :icon="advisor.user_status === 0 ? 'ni-unlock-fill' : 'ni-lock-fill'" v-on:click="F_changeAccountStatus([advisor])"></table-action>
              </template>
              <li>
                <drop-down-list>
                  <list-item icon="ni-eye" :text="$t('View Details')"
                             :to="{name: (userRole === 'is-company' ? 'comAdvisorProfile' : 'advisorProfile'), params: {id: advisor.id} }"></list-item>
                  <list-item icon="ni-activity-round" :text="$t('Activities')"
                             :to="{name: (userRole === 'is-company' ? 'comAdvisorProfile' : 'advisorProfile'), params: {id: advisor.id, tab: 'activities'}}"></list-item>
                  <list-item v-if="!isDesktop"
                             :icon="advisor.user_status === 0 ? 'ni-unlock-fill' : 'ni-lock-fill'"
                             :text="advisor.user_status === 0 ? $t('Activate'): $t('Suspend')"
                             v-on:click="F_changeAccountStatus([advisor])"></list-item>
                </drop-down-list>
              </li>
            </ul>
          </table-col>
        </table-row>

      </special-table>

    </div>
    <div class="card-inner" v-if="pagination.totalRecords > 10">
      <pagination
          v-model="pagination.page"
          :records="pagination.totalRecords"
          :per-page="10"
          @paginate="paginationCallback"
          theme="bootstrap4"
          :options="{chunk: 5, texts:{count: '||'}}"/>
    </div>
  </div>
  <ion-modal
      :is-open="userNotes.modalOpen"
      :css-class="isDesktop ? 'modal-web': ''"
      @didDismiss="userNotes.modalOpen=false">
    <i-modal
        :title="$t('Advisor')+' '+$t('Notes')"
        @modal-close="userNotes.modalOpen=false">
      <div class="lead_note_single" v-for="(note, index) in userNotes.records" :key="'ln'+index">
        <div>{{note.note}}</div>
        <div class="small mt-1 nk-text-dark">{{note.created_at}}, {{$t('added by')+' '+note.added_by}}</div>
      </div>
      <div class="text-muted" v-if="!userNotes.records.length">{{$t('No notes found.')}}</div>
      <template v-slot:footer>
        <nk-button type="primary" size="sm" v-on:click="[addEditNote.modalOpen=true, Object.assign(addEditNote.note, {id: 0,note: '',user_id: userNotes.user_id})]">
          <nio-icon icon="ni ni-plus"></nio-icon> {{$t('Add Note')}}
        </nk-button>
      </template>
    </i-modal>
  </ion-modal>
  <ion-modal
      :is-open="addEditNote.modalOpen"
      :css-class="isDesktop ? 'modal-web': ''"
      @didDismiss="addEditNote.modalOpen=false">
    <i-modal
        :title="$t('Add Note')"
        @modal-close="addEditNote.modalOpen=false">
      <textarea class="form-control" v-model="addEditNote.note.note"></textarea>
      <template v-slot:footer>
        <nk-button type="primary" size="sm" v-on:click="F_addUserNote"><nio-icon icon="ni ni-plus"></nio-icon> {{$t('Add Note')}}</nk-button>
      </template>
    </i-modal>
  </ion-modal>
  <ion-modal
      :is-open="advisorAddEditForm.modalOpen"
      @didDismiss="advisorAddEditForm.modalOpen=false"
      :css-class="isDesktop ? 'modal-web': ''">
    <add-advisor-modal :company-id="companyId" @modal-close="advisorAddEditForm.modalOpen=false" @success="onAddEditAdvisor"></add-advisor-modal>
  </ion-modal>
</template>

<script>
import {
  IonModal,
  IonSpinner,
  loadingController,
  toastController,
  isPlatform,
} from '@ionic/vue';
import {defineComponent, onMounted, reactive, ref} from 'vue';

import "bootstrap"

import Pagination from "v-pagination-3"
import {SpecialTable,TableAction, TableCol, TableRow} from "@core/components/special-table";
import UserSmallCard from "@/views/components/user-cards/UserSmallCard";
import {DropDownList, ListItem} from "@core/components/dropdown-list";
import useAdvisors from "@/views/admin/advisors/useAdvisors";
import {useI18n} from "vue-i18n";
import IModal from "@core/components/ion-modal/IModal";
import useUser from "@core/comp-functions/User"
import storeHelper from '@/store/helper'
import AddAdvisorModal from "@/views/admin/advisors/components/AddAdvisorModal";

export default defineComponent({
  components: {
    AddAdvisorModal,
    IModal,
    ListItem,
    DropDownList,
    TableAction,
    UserSmallCard,
    SpecialTable,
    TableCol,
    TableRow,
    IonModal,
    IonSpinner,
    Pagination,
  },
  props:{
    companyId:{
      required: true,
    }
  },
  setup(props) {

    const i18n = useI18n()
    const isDesktop = isPlatform('desktop')
    const{ addUserNote, getAdvisors, getUserNotes, updateActiveStatus } = useAdvisors()
    const{changeAccountStatus,resendInvitationEmail} = useUser()
    const{userRole} = storeHelper()

    let loadingData = ref(false)
    let advisors     = ref([])

    let pagination = reactive({
      page: 1,
      totalRecords: 0,
    })
    let userNotes = reactive({
      modalOpen: false,
      total_records: 0,
      records: [],
      user_id: 0,
    });
    let addEditNote = reactive({
      modalOpen: false,
      note:{
        id: 0,
        note: '',
        user_id: 0,
      }
    });
    let advisorAddEditForm = reactive({
      modalOpen: false,
    })
    const paginationCallback = async () => {
      await F_getAdvisors()
    }

    const F_getAdvisors = (reset) => {
      loadingData.value = true
      if(reset){
        pagination.page = 1
      }
      getAdvisors({
        page: pagination.page,
        company_id: props.companyId,
      })
      .then(r=>{
        advisors.value = r.data
        if(pagination.page === 1){
          pagination.totalRecords = r.total_records
        }
      })
      .catch(er=>{
        toastController.create({position: 'top', color: 'danger', message: er, duration: 4000}).then((ts) => ts.present() )
      })
      .then(()=> loadingData.value = false)
    }

    onMounted(()=> F_getAdvisors())

    const F_changeAccountStatus = ( _advisors, action ) => {
      changeAccountStatus(_advisors, 'advisor', action)
      .then(r=>{
        for(let y in advisors.value){
          if(r.ids.includes(advisors.value[y].user_id)){
            advisors.value[y].user_status = r.status
          }
        }
      })
      .catch(()=>{})
    }
    const F_getUserNotes = async (user_id)=>{
      const l = await loadingController.create({message: i18n.t('Please wait...')});
      await l.present()
      getUserNotes(user_id).then((r)=>{
        userNotes.user_id = user_id
        userNotes.total_records = r.total_records
        userNotes.records = r.records
        userNotes.modalOpen = true;
      })
      .catch((m)=>{
        toastController.create({header: i18n.t('Error'), message: m, duration: 3500, position: 'top', color: 'danger'}).then((t)=> t.present())
      })
      .then(()=> l.dismiss())
    }
    const F_addUserNote = async ()=>{
      const l = await loadingController.create({message: i18n.t('Please wait...')});
      await l.present()
      addUserNote(addEditNote.note).then((r)=>{
        if(parseInt(addEditNote.note.id) < 1){
          userNotes.records.unshift(r)
        }
        else{
          for(let x in userNotes.records){
            if(parseInt(userNotes.records[x].id) === parseInt(addEditNote.note.id)){
              userNotes.records[x].note = addEditNote.note.note
              break;
            }
          }
        }
        addEditNote.modalOpen = false
        for(let x in advisors.value){
          if(parseInt(advisors.value[x].user_id) === parseInt(addEditNote.note.user_id)){
            advisors.value[x].notes_count++;
            break;
          }
        }
      })
      .catch((m)=>{
        toastController.create({header: i18n.t('Error'), message: m, duration: 3500, position: 'top', color: 'danger'}).then((t)=> t.present())
      })
      .then(()=> l.dismiss())
    }

    const changeAdvisorActiveStatus = (advisor) => {
      updateActiveStatus(advisor)
      .then((new_status)=>{
        advisor.active_status = new_status
      })
      .catch(()=>{})
    }

    const onAddEditAdvisor = (d) => {
      if(d.action === 'added'){
        advisors.value.unshift(d.data)
      }
      else{
        for(let x in advisors.value){
          if(parseInt(advisors.value[x].id) === parseInt(d.data.id)){
            advisors.value[x] = d.data
          }
        }
      }
    }

    const F_resendUserInvitationEmail = (advisor) => {
      resendInvitationEmail(advisor.user_id).then(()=>{
        advisor.last_invitation_time = 'today'
      }).catch(()=>{})
    }

    return {
      addEditNote,
      advisorAddEditForm,
      advisors,
      changeAdvisorActiveStatus,
      F_getAdvisors,
      F_resendUserInvitationEmail,
      isDesktop,
      loadingData,
      onAddEditAdvisor,
      pagination,
      paginationCallback,

      F_addUserNote,
      F_changeAccountStatus,
      F_getUserNotes,

      userNotes,
      userRole,
    }
  }
});

</script>
