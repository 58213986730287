<template>
  <div class="nk-block px-4">
    <div class="nk-block-head">
      <h5>{{ $t('Mandate information') }}</h5>
    </div>
    <div v-if="spinner" class="text-center">
      <ion-spinner></ion-spinner>
    </div>
    <template v-else>
      <template v-if="!eMandates.length">
        <div class="alert alert-info">{{ $t('payment.mandate.not_signed_1') }}</div>
      </template>
      <div class="between-center flex-wrap flex-md-nowrap g-3" v-for="(mandate,i) in eMandates" :key="'cc_mandate'+i">
        <div class="nk-block-text">
          <h6> {{ mandate.type+' '+ $t('Mandate') }}</h6>
          <div class="text-muted">
            <div class="nk-text-muted">{{ $t('Mandate') }} {{ $t('Code') }}: </div>
            <span>{{ mandate.code}}</span>
            <div class="nk-text-muted">{{ $t('Signed')+' '+$t('on')}}: </div>
            <span>{{ mandate.signed_date}}</span>
          </div>
        </div>
      </div>
    </template>
  </div>
</template>

<script>
import axios from "@/libs/axios";
import {ref, onMounted, watch} from "vue";
import {IonSpinner} from "@ionic/vue";

export default {
  props:{
    currentTab: null,
    userId:{
      required: true,
    }
  },
  components:{IonSpinner},
  setup( props ){

    let eMandates = ref([])
    let spinner = ref(false)
    const getE_MandateInfo = () => {
      spinner.value = true
      eMandates.value.splice(0)
      axios.get(`/api/admin/user/${props.userId}/mandate_info`)
          .then( resp => {
            if(resp.data.success){
              for(let x in resp.data.data.mandates){
                eMandates.value.push(resp.data.data.mandates[x])
              }
            }
          })
          .catch(error => {
            console.log(error)
          })
          .then(() => spinner.value = false)
    }

    onMounted(()=>{
      if(props.currentTab === 'eMandates'){
        getE_MandateInfo()
      }
    })
    watch( ()=> props.userId, (n)=>{
      if(n && props.currentTab === 'eMandates'){
        getE_MandateInfo()
      }
    })

    return{
      eMandates,
      spinner,
    }
  }
}
</script>
